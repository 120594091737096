.context-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    z-index: 9999;
    width: 120px;
}

.context-modal-content {
    padding: 7px 15px 7px 15px;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.context-modal-content:hover {
    background-color: #f0f3f9;
}

.context-modal-content p {
    margin: 0
}

.context-modal-content_helper {
    opacity: 0.5;
}