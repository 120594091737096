.auditItem {
    text-align: left;
    padding: 0.25em;
    display: flex;
    flex-direction: column;
}

.deleted {
    background-color: #EBCEDA;
}

.auditItem:hover{
    background-color: var(--hover-color);
}

h3 {
    padding: 0;
    margin: 0;
}

.show {
   display: block; 
}

.auditSubItem {
    margin-left: 1em;
}

.modifications {
}

.auditModification {
    padding-left: 1em;
    padding-bottom: 0.5em;
    display: flex;
    flex-direction: column;
}

.mods-header{
    display: flex;
    justify-content: space-between;
}

.auditItem--selected {
    background-color: var(--hover-color);
}

.modifications--hidden {
    display: none;
}