:root {
  --bg-color-gray: #f1f3f4;
  --hover-color: lightyellow;
}

#title {
  text-align: left;
  padding: 0 10px;
}

.topnav {
  min-height: 32px;
  width: 100vw;
  background-color: lightGray;
  display: inline-block;
}

.topnav * {
  cursor: pointer;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

.App-button {
  border: 0;
  display: block;
  width: 100%;
  background: green;
  color: white;
  font-size: 1.2em;
  padding: 0.5em;
}

.App-viewer {
  position: relative;
  flex: 1;
}

.hidden {
  display: none;
}
