.auditBar {
    background-color: var(--bg-color-gray);
    max-width: 350px;
    display: flex;
    height: 100vh;
}

.auditList {
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;
}

.hidden {
   display: none; 
}
