.sidenav {
  overflow-y: scroll;
  height: 100vh;
  background-color: #fff;
  display: inline-block;
  width: 220px;
  border-right: solid #f6f8f9 2px;
}

.sidenav * {
  cursor: pointer;
}

.sidenav-title-container {
  padding: 4.5px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 10px;
}

.sidenav-title {
  height: 36px;
  font-weight: normal;
  display: flex;
  justify-content: start;
  align-items: center;
}

.sidenav-subtitle {
  font-size: 13px;
  text-align: start;
  opacity: 0.8;
  margin-left: 10px;
}

.sidenav-element {
  margin: 0px 7px;
  display: flex;
  align-items: center;
  text-align: left;
  font-size: 15px;
  border-radius: 0.25rem;
}

.sidenav-element--selected {
  background-color: #eeeff2;
}

.sidenav-element * {
  padding: 12px 0px;
}

.sidenav-element-icon {
  margin-left: 20px;
}

.sidenav-element-text {
  margin-left: 12px;
}

.sidenav-divider {
  opacity: 0.2;
  margin: 8px 6px;
}

#sidenav-hideButton {
  width: 40px;
}

#sidenav-hideButton p {
  margin: 10px 2px;
  cursor: pointer;
}

.chevron-icon {
  font-variation-settings: "wght" 100 "OPSZ" 40;
  font-size: 25px;
}

.chevron-icon-close:hover {
  background-color: rgb(238 239 242);
  border-radius: 0.25rem;
}

.chevron-icon-open-container {
  background-color: #34699a;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 1px 2px #3d424e80;
}

.chevron-icon-open {
  color: white;
}
