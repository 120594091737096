.PSPDFKitContainer {
  width: 100%;
  height: 100vh;
  display: flex;
}

.annexUploadWatermarkContainer {
  min-width: 260px;
  border-right: 1px solid rgb(61, 66, 78, .5);
}

.annexUploadWatermarkContainer h2 {
  font-size: 18px;
  color: #1d1d1d;
  padding: 0px 10px;
}

.annexUploadWatermarkContainer-subtitle {
  font-size: 14px;
  color: #1d1d1d;
  padding: 0px 10px;
}

.preview-page {
  margin: auto;
  width: 200px;
  height: 290px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid black;
}

.preview-page-row {
  display: flex;
  justify-content: space-between;
}

.preview-watermark {
  width: 40px;
  height: 10px;
  margin: 2px;
  background-color: red;
}

.annexUploadContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f0f3f9;
}

.pdfPagesContainer {
  width: 100%;
  height: 100%;
  padding: 20px 0 20px 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  overflow: auto;
}

.annexUploadTopBar {
  width: 100%;
  height: 44px;
  background-color: #fcfdfe;
  -webkit-box-shadow: 0px 1px 2px rgb(61, 66, 78, .5);
  box-shadow: 0px 1px 2px rgb(61, 66, 78, .5);
  display: flex;
}

.annexUploadBottomBar {
  width: 100%;
  height: 66px;
  background-color: #fcfdfe;
  display: flex;
  bottom: 0;
  justify-content: end;
  align-items: center;
}

.barItem {
  display: flex;
  padding: 10px;
  cursor: pointer;
  color: rgb(61, 66, 78);
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}

.barItem svg {
  width: 23px;
  height: 27px;
}

.barItem p {
  margin-left: 5px;
}

.barItem:hover {
  background-color: #f0f3f9;
}

.bottomBarItem {
  color: #fff;
  margin: 0px 16px;
  padding: 0;
  background-color: #4636e3;
  border: 1px solid #2e1fc5;
  border-radius: 3px;
  width: 103px;
  height: 37px;
  min-height: 37px;
  justify-content: center;
  align-items: center;
}

.bottomBarItem:hover {
  background-color: #4636e3;
  border: 1px solid #2e1fc5;
}

.bottomBarItem:focus {
  background-color: #2b1cc1;
  border: 1px solid #1b0e99;

}



.pdfPage {
  box-shadow: rgba(43, 46, 54, 0.15) 0px 1.5px 2px 0px, rgba(43, 46, 54, 0.15) 0px 3px 6px 0px;
  margin: 4px;
  border-radius: 1px;
}

.pdfPage_selected {
  box-shadow: rgb(94, 92, 235) 0px 0px 0px 4px;
}

.loadingContainer {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.dropdown {
  position: absolute;
  top: 43px;
  background-color: #f0f3f9;
  left: 0;
  -webkit-box-shadow: 0px 1px 2px rgb(61, 66, 78, .5);
  box-shadow: 0px 1px 2px rgb(61, 66, 78, .5);
  z-index: 10;
}

.relative {
  position: relative;
}